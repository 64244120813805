<template>
<CRow>
  <CCol col="12" xl="12">
    <CCard>
      <CCardHeader>
        <CCol col="6" class="styleHeader">  {{ $t('message.showSupplier')}}</CCol>
      </CCardHeader>
      <CCardBody>
        <div class="row">
            <div class="col-md-6">
                <table class="table table-bordered">
                  <tr>
                    <th> {{$t('message.supplierName')}}  </th>
                    <td>{{ suppliers.name }}</td>
                  </tr>
                  <tr>
                    <th> {{$t('message.supplierEmail')}} </th>
                    <td>{{ suppliers.email }}</td>
                  </tr>
                  <tr>
                    <th> {{$t('message.supplierPhone')}}  </th>
                    <td>{{ suppliers.phone}}</td>
                  </tr>

                </table>
            </div>
          </div>
        <div class="row" v-if="suppliers.orders[0]">
            <div class="col-md-10 m-auto">
                <table class="table table-bordered">
                  <tr>
                    <th> {{$t('message.orderId')}}  </th>
                    <th> {{$t('message.total')}}  </th>
                    <th>  {{ $t('message.orderCreatedAt') }}  </th>
                  </tr>
                   <tr v-for=" order in suppliers.orders" :key="order.id">
                    <!-- <td v-if="$i18n.locale == 'English'">{{ product.name }}</td>
                    <td v-if="$i18n.locale == 'العربية'">{{ product.name_ar }}</td> -->
                    <td>{{ order.order_no }}</td>
                    <td>{{ order.total }}</td>
                    <td>{{ moment(order.date_format)   }}</td>
                  </tr>
                </table>
            </div>
        </div>
        <div v-else class="row">
                <h3 class="mb-3 text-center">{{$t('message.noOrders')}}</h3>
        </div>
        <div class="row" v-if="suppliers.invoices[0]">
            <div class="col-md-10 m-auto">
                <table class="table table-bordered">
                  <tr>
                    <th> {{$t('message.invoicesId')}}  </th>
                      <th> {{$t('message.orderId')}}  </th>
                       <th> {{$t('message.pricePaid')}}  </th>
                       <th>  {{ $t('message.invoiceCreatedAt') }}  </th>
                  </tr>
                   <tr v-for=" invoice in suppliers.invoices" :key="invoice.id">
                    <!-- <td v-if="$i18n.locale == 'English'">{{ product.name }}</td>
                    <td v-if="$i18n.locale == 'العربية'">{{ product.name_ar }}</td> -->
                    <td>{{ invoice.id }}</td>
                    <td>ORD{{ invoice.order_id }}</td>
                     <td>{{ invoice.price_paid }}</td>
                     <td>{{ moment(invoice.date_format)  }}</td>
                  </tr>
                </table>
            </div>
        </div>
         <div v-else class="row">
                <h3 class="mb-3 text-center">{{$t('message.noInvoices')}}</h3>
        </div>
        <CButton color="btn btn-primary" @click="goBack"> {{ $t('message.back')}}</CButton>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ShowSupplier',
  data () {
    return {
      showModal: false,
      suppliers: [],
      products: [],
      moment: moment
    }
  },

  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}suppliers/${this.$route.params.id}`)
      .then((response) => {
        this.suppliers = response.data.data
        console.log(response.data.data)
      })
  },
  methods: {
    goBack () {
      this.$router.push({ path: '/suppliers' })
    }
  }
}
</script>
<style scoped>
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
</style>
